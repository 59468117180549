
import Vue from "vue";
import Component from "vue-class-component";

import Divider from "@/components/ewa/common/Divider.vue";
import { StoreActionTypes } from "@/store/types";
import { EwaOrder, EwaUser } from "@/services/types/ewa";

@Component({
  components: {
    Divider,
  },
  watch: {
    formModel: "checkForm",
  },
})
export default class EwaPaymentModal extends Vue {
  async mounted() {
    await this.$store.dispatch(StoreActionTypes.GET_ORDER);
    this.fillModel();
    this.formModel.email = this.user.email;
    this.formModel.inn = this.user.company.inn;
    this.formModel.sum = this.paymentOptions.sort((a, b) => b - a)[0];

    //this.paymentOptions = this.user.tariffs.sort((a, b) => a.price - b.price).map((v => v.price));
  }

  get user(): EwaUser {
    return this.$store.getters.getEwaUser;
  }

  get orderModel(): EwaUser {
    return this.$store.getters.getEwaUser;
  }

  fillModel(): void {
    if (this.orderModel) {
      this.formModel.email = this.orderModel.email;
      this.formModel.inn = this.orderModel.company.inn;
    }
  }

  public formModel: EwaOrder = {
    email: null,
    inn: null,
    sum: null,
  };

  formErrors = {
    email: false,
    inn: false,
    sum: false,
  };

  get isLoading() {
    return this.$store.getters.ewaDialogIsLoading;
  }

  get currentQr() {
    return this.$store.getters.getCurrentQr;
  }

  public paymentOptions = [9000, 15000, 27000];

  choosePaymentOption(option) {
    this.formModel.sum = option;
  }

  checkForm(model): boolean {
    let check = true;

    if (!model.email || !model.email.includes("@")) {
      this.formErrors.email = true;
      check = false;
    } else {
      this.formErrors.email = false;
    }

    if (!model.inn || model.inn.length < 10 || model.inn.length > 12) {
      this.formErrors.inn = true;
      check = false;
    } else {
      this.formErrors.inn = false;
    }

    if (!model.sum) {
      this.formErrors.sum = true;
      check = false;
    } else {
      this.formErrors.sum = false;
    }

    return check;
  }

  async setOrder() {
    if (!this.checkForm(this.formModel)) {
      (window as any).error("Проверьте правильность заполнения полей");
      return;
    }

    await this.$store.dispatch(StoreActionTypes.SET_ORDER, this.formModel);
  }

  close() {
    (this.$root as any).closeDialog();
  }
}
